import { get, flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {
  EXPERIMENT_HASHTAG_PAGE_USE_FEED_PAGE,
  EXPERIMENT_PROD_OOI_EDITOR,
  EXPERIMENT_RICOS_EMBED_BUTTONS,
} from '@wix/communities-blog-experiments';
import {
  isExperimentEnabled,
  getIsPostPageBundle,
  getImageUrl,
  getExperiments,
} from '@wix/communities-blog-client-common';
import { RicosViewerWrapper } from '@wix/communities-blog-client-common/dist/esm/components';
import { WixRicosViewer } from '@wix/ricos-viewer';
import { Version, isHexColor } from 'wix-rich-content-common';
import { TextSelectionToolbar, TwitterButton } from 'wix-rich-content-text-selection-toolbar';
import { pluginCodeBlock } from 'wix-rich-content-plugin-code-block/viewer';
import { pluginDivider } from 'wix-rich-content-plugin-divider/viewer';
import { pluginLinkButton, pluginActionButton } from 'wix-rich-content-plugin-button/viewer';
import { pluginGallery } from 'wix-rich-content-plugin-gallery/viewer';
import { pluginGiphy } from 'wix-rich-content-plugin-giphy/viewer';
import { pluginHashtag } from 'wix-rich-content-plugin-hashtag/viewer';
import { pluginHtml } from 'wix-rich-content-plugin-html/viewer';
import { pluginImage } from 'wix-rich-content-plugin-image/viewer';
import { pluginLineSpacing } from 'wix-rich-content-plugin-line-spacing/viewer';
import { pluginIndent } from 'wix-rich-content-plugin-indent/viewer';
import { pluginLink } from 'wix-rich-content-plugin-link/viewer';
import { pluginLinkPreview } from 'wix-rich-content-plugin-link-preview/viewer';
import { pluginTextColor, pluginTextHighlight } from 'wix-rich-content-plugin-text-color/viewer';
import { pluginVideo } from 'wix-rich-content-plugin-video/viewer';
import { pluginFileUpload } from 'wix-rich-content-plugin-file-upload/viewer';
import { pluginHeadersMarkdown } from 'wix-rich-content-plugin-headers-markdown/viewer';
import { pluginVerticalEmbed } from 'wix-rich-content-plugin-vertical-embed/viewer';
import { connect } from '../../../common/components/runtime-context';
import withDeviceType from '../../../common/hoc/with-device-type';
import { getAppSettings } from '../../../common/selectors/app-settings-base-selectors';
import { getSection } from '../../../common/selectors/section-selectors';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import {
  isSeo,
  getInstance,
  getUrl,
  isEditor,
  isPreview,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getLocale } from '../../../common/selectors/locale-selectors';
import { getIframeSandboxDomain } from '../../../common/selectors/rce-props-selectors';
import withMediaHosts from '../../../common/hoc/with-media-hosts';
import Wix from '../../../common/services/wix-sdk-polyfill';
import { getTwitterEnabled } from '../../../common/selectors/app-settings-selectors';

const RicosViewer = ({
  initialRawState,
  appSettings,
  isSeo,
  isInEditor,
  isInPreview,
  isMobile,
  locale,
  isPostPageBundle,
  instance,
  pageUrl,
  hashtagNavigate,
  sectionUrl,
  imageHost,
  disableTheme,
  requestFileDownloadUrl,
  showMessage,
  fileDownloaded,
  isHashtagPageAsFeedPageEnabled,
  isExperimentProdOOIEditorEnabled,
  isRicosEmbedButtonsEnabled,
  isTwitterEnabled,
  ...rest
}) => {
  const isOOI = true;
  const content = initialRawState;
  const rceVersion = Version.currentVersion;

  const props = {
    WixRicosViewer,
    content,
    pluginCodeBlock,
    pluginDivider,
    pluginLinkButton,
    pluginGallery,
    pluginGiphy,
    pluginHashtag,
    pluginHtml,
    pluginImage,
    pluginLineSpacing,
    pluginIndent,
    pluginLink,
    pluginLinkPreview,
    pluginTextColor,
    pluginTextHighlight,
    pluginVideo,
    pluginFileUpload,
    pluginHeadersMarkdown,
    pluginActionButton,
    pluginVerticalEmbed,
    rceVersion,
    appSettings,
    isSeo,
    isInEditor,
    isInPreview,
    isOOI,
    isMobile,
    locale,
    isPostPageBundle,
    instance,
    getImageUrl: (image) => getImageUrl({ image, imageHost }),
    pageUrl,
    isHexColor,
    hashtagNavigate,
    sectionUrl,
    disableTheme,
    isHashtagPageAsFeedPageEnabled,
    isExperimentProdOOIEditorEnabled,
    isRicosEmbedButtonsEnabled,
    TextSelectionToolbar,
    TwitterButton,
    isTwitterEnabled,
    Wix,
    actions: {
      requestFileDownloadUrl,
      showMessage,
    },
    bi: {
      fileDownloaded,
    },
  };

  return <RicosViewerWrapper {...rest} {...props} />;
};

RicosViewer.propTypes = {
  appSettings: PropTypes.object.isRequired,
  isSeo: PropTypes.bool.isRequired,
  isInEditor: PropTypes.func.isRequired,
  isInPreview: PropTypes.func.isRequired,
  isPostPageBundle: PropTypes.func.isRequired,
  hashtagNavigate: PropTypes.func.isRequired,
  pageUrl: PropTypes.string.isRequired,
  imageHost: PropTypes.string.isRequired,
  videoHost: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  instance: PropTypes.string.isRequired,
  sectionUrl: PropTypes.string.isRequired,
  disableTheme: PropTypes.bool.isRequired,
  isHashtagPageAsFeedPageEnabled: PropTypes.bool.isRequired,
  isExperimentProdOOIEditorEnabled: PropTypes.bool.isRequired,
  isRicosEmbedButtonsEnabled: PropTypes.bool.isRequired,
  isTwitterEnabled: PropTypes.bool.isRequired,
  parentClass: PropTypes.string,
  experiments: PropTypes.object.isRequired,
};

RicosViewer.defaultProps = {
  disableTheme: true,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  appSettings: getAppSettings(state),
  isSeo: isSeo(state),
  isInEditor: isEditor(state),
  isInPreview: isPreview(state),
  isPostPageBundle: getIsPostPageBundle(state),
  locale: getLocale(state),
  postId: get(ownProps, 'post._id'),
  instance: getInstance(state),
  pageUrl: getUrl(state),
  hashtagNavigate: actions.navigateWithinBlog,
  section: getSection(state),
  sectionUrl: getSectionUrl(state),
  iframeSandboxDomain: getIframeSandboxDomain(state),
  requestFileDownloadUrl: actions.requestFileDownloadUrlPromisified,
  showMessage: actions.showMessage,
  fileDownloaded: actions.fileDownloaded,
  isHashtagPageAsFeedPageEnabled: isExperimentEnabled(state, EXPERIMENT_HASHTAG_PAGE_USE_FEED_PAGE),
  isExperimentProdOOIEditorEnabled: isExperimentEnabled(state, EXPERIMENT_PROD_OOI_EDITOR),
  isRicosEmbedButtonsEnabled: isExperimentEnabled(state, EXPERIMENT_RICOS_EMBED_BUTTONS),
  isTwitterEnabled: getTwitterEnabled(state),
  experiments: getExperiments(state),
});

export default flowRight(connect(mapRuntimeToProps), withDeviceType, withMediaHosts)(RicosViewer);
